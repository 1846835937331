.user-info-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user-info-box {
  display: flex;
  align-items: center;
  background: #212121; /* Use the specified background color */
  border: 1px solid #e949de; /* Use the specified border color */
  padding: 10px;
  border-radius: 8px;
  margin: 5px; /* Add margin to separate the boxes */
  height: 40px; /* Set a fixed height to ensure both boxes have the same height */
}

.wallet-icon {
  margin-right: 10px;
  font-size: 1.2rem; /* Reduce the size of the icon */
}

.address-text {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: white; /* Ensure the text is readable on the dark background */
}
